<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-1" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="left" class="pt-0 pb-0">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Fecha desde -->
                  <v-col cols="3" sm="6" md="3" class="p-2">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model.trim="fechaDesdeSelected"
                          label="Fecha consulta desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          @change="setTipoProceso(), resetProcesoMasivo()"
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="
                          fechaDesdeSelected = formatDate(fechaDesde);
                          setTipoProceso();
                          resetProcesoMasivo();
                        "
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Tipo de proceso -->
                  <v-col cols="4" md="5" class="p-2 pr-0">
                    <v-autocomplete
                      outlined
                      dense
                      :disabled="fechaDesdeSelected == null"
                      label="Tipo de proceso"
                      v-model="tipoProcesoSelected"
                      @change="resetProcesoMasivo()"
                      :rules="rules.required"
                      :items="tipoProcesos"
                      return-object
                      item-text="bcpTipoNombre"
                      item-value="bcpTipoId"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1" class="m-0 pl-1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          size="25"
                          color="primary"
                          v-on="on"
                          :disabled="
                            !tipoProcesoSelected || !fechaDesdeSelected
                          "
                          @click="openModal(tipoProcesoSelected)"
                        >
                          {{ treeDots }}
                        </v-icon>
                      </template>
                      <span>Ver configuración de tipos de procesos</span>
                    </v-tooltip>
                  </v-col>
                  <!-- periodo -->
                  <v-col cols="3" md="2" class="p-2 pl-0">
                    <v-text-field
                      ref="periodo"
                      v-model="periodo"
                      label="Período"
                      outlined
                      @change="resetProcesoMasivo()"
                      dense
                      clearable
                      v-mask="'######'"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      hint="Formato AAAAMM"
                      :rules="rules.periodoYyyyMm"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="py-0 pl-2">
                    <v-file-input
                      v-if="
                        tipoProcesoSelected != null &&
                          tipoProcesoSelected.desdeArchivo
                      "
                      ref="file"
                      v-model="excelFile"
                      :disabled="loadingFile"
                      :loading="loadingFile"
                      id="fileUpload"
                      type="file"
                      dense
                      label="Excel"
                      placeholder="Seleccionar el archivo Excel"
                      accept=".xls,.xlsx,.csv"
                      outlined
                      :show-size="1000"
                      @change="readExcel"
                      :rules="rules.required"
                      prepend-icon="mdi-file-find-outline"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          :color="!loadingFile ? 'primary' : 'grey lighten-1'"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-btn
                      v-if="
                        tipoProcesoSelected != null &&
                          tipoProcesoSelected.desdeArchivo
                      "
                      color="primary"
                      class="to-right"
                      outlined
                      @click="openModalSeeFormatoArchivo()"
                    >
                      Ver formato
                    </v-btn></v-col
                  >
                  <v-col cols="5" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsNuevoMovMasivoPadrones"
            :loading="isLoading"
            sort-by="apellidoNombre"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class=" mt-2 py-4 to-right fontsize"
                  align="end"
                  v-bind="attrs"
                  outlined
                  small
                  :disabled="itemsNuevoMovMasivoPadrones.length == 0"
                  @click="exportActualizacionGenerada"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-btn
              :disabled="itemsNuevoMovMasivoPadrones.length == 0"
              class="m-2 mr-0 to-right"
              align="end"
              color="primary"
              @click="openModalProcesarMovMasivo()"
            >
              Procesar movimiento
            </v-btn>
          </v-col>

          <v-dialog v-model="dialog" persistent max-width="80rem">
            <v-card :loading="loadingProceso">
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  indeterminate
                ></v-progress-linear>
              </template>
              <!-- Fecha desde -->
              <v-col cols="4" sm="6" md="4" class="pb-0 pt-5">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaDesdeSelected"
                      label="Fecha de movimiento"
                      :append-icon="calendarIcon"
                      disabled
                      v-bind="attrs"
                      outlined
                      clearable
                      dense
                      hint="Formato DD/MM/AAAA"
                      v-mask="'##/##/####'"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                      v-on="on"
                      :rules="rules.required.concat(rules.validDate)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaDesde"
                    no-title
                    @change="fechaDesdeSelected = formatDate(fechaDesde)"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-data-table
                :headers="headersActualizacion"
                :items="itemsMovMasivoGenerado"
                class="elevation-2"
                :search="searchActualizacion"
                :loading="isLoading"
              >
                <template v-slot:[`item.tipoYDoc`]="{ item }"
                  ><span
                    >{{ item.tipoDoc }} - {{ item.nroDoc }}
                  </span></template
                >
                <template v-slot:[`item.importeNuevo`]="{ item }">
                  <span>{{ addDecimals(item.importeNuevo) }}</span>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="10">
                        <v-text-field
                          v-model="searchActualizacion"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
              </v-data-table>
              <v-card-actions class="m-0">
                <v-spacer></v-spacer>
                <v-col cols="3" md="3" class="p-1 m-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        color="primary"
                        class="to-right py-4"
                        v-bind="attrs"
                        outlined
                        @click="exportarNuevosMovMasivos"
                      >
                        Exportar lista completa
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-btn
                  outlined
                  @click="dialog = false"
                  :disabled="loadingProceso"
                >
                  Cerrar
                </v-btn>

                <v-btn
                  class="m-2 to-right"
                  align="end"
                  color="primary"
                  :disabled="
                    itemsMovMasivoGenerado.length == 0 || loadingProceso
                  "
                  @click="guardarNuevoMovMasivo()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-dialog
          v-if="modalExportarExcel"
          v-model="modalExportarExcel"
          max-width="60%"
          persistent
        >
          <v-card>
            <v-container>
              <v-card-title>
                <v-col cols="12" md="12">
                  <span class="pl-1 primary--text"
                    >Se generará un archivo con el detalle de los errores de la
                    importación</span
                  >
                </v-col>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="to-right"
                  text
                  @click="exportExcel()"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-if="openModalEdit"
          v-model="openModalEdit"
          max-width="60%"
          persistent
          @keydown.esc="closeModalEdit"
        >
          <EditProcesoMovMasivo
            :configProceso="openVerConfigTipoProceso"
            :seeConfig="true"
            @closeAndReload="closeModalEdit"
          ></EditProcesoMovMasivo>
        </v-dialog>
        <FormatoArchivoDialog
          :formatoTitle="formatoTitle"
          :headers="headersFormato"
          :datosFormatoArchivo="datosFormatoArchivo"
          :dataToExport="dataToExportFormato"
          :openFormatoArchivoDialog.sync="showSeeFormatModal"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditProcesoMovMasivo from "@/components/modules/afiliaciones/afiliados/EditProcesoMovMasivo.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";

export default {
  name: "NuevoMovMasivoPadrones",
  components: {
    PageHeader,
    GoBackBtn,
    FiltersSelected,
    Ayuda,
    EditProcesoMovMasivo,
    FormatoArchivoDialog
  },
  directives: { mask },
  data: vm => ({
    tipoProcesoSelected: null,
    title: enums.titles.NUEVO_MOV_MASIVO_PADRONES,
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.NUEVO_PROC_MOV_MASIVO,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showIcon: true,
    calendarIcon: enums.icons.CALENDAR,
    treeDots: enums.icons.TREE_DOT,
    routeToGo: "ConsultaMovMasivoPadrones",
    tabBuscarAportes: true,
    modalExportarExcel: false,
    dialog: false,
    loadingProceso: false,
    openVerConfigTipoProceso: {},
    itemsNuevoMovMasivoPadrones: [],
    filtersApplied: [],
    itemsMovMasivoGenerado: [],
    showFilters: true,
    periodo: "",
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: false,
    isLoading: false,
    search: "",
    searchActualizacion: "",
    searchIcon: enums.icons.SEARCH,
    tipoProcesos: [],
    headers: [
      {
        text: "Convenio",
        value: "osNombre",
        sortable: false,
        align: "start"
      },
      {
        text: "Plan",
        value: "planNombre",
        sortable: false,
        align: "center"
      },
      {
        text: "Cantidad de afiliados",
        value: "cantidad",
        sortable: false,
        align: "end"
      }
    ],
    headersActualizacion: [
      {
        text: "Número de afiliado",
        value: "numero",
        sortable: false
      },
      {
        text: "Tipo y N° de documento",
        align: "start",
        value: "tipoYDoc",
        sortable: false
      },
      {
        text: "Apellido/s y nombre/s",
        value: "benNombre",
        sortable: false
      },
      {
        text: "Convenio",
        value: "convenio",
        sortable: false
      },
      {
        text: "Plan",
        value: "planNombre",
        sortable: false,
        align: "start"
      },
      {
        text: "Fórmula usada",
        value: "formulaUsada",
        align: "start",
        sortable: false
      }
    ],
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaDesde: new Date(new Date()).toISOString().substr(0, 10),
    fechaDesde: null,
    fechaDesdeSelected: null,
    menu1: false,
    menu2: false,
    rules: rules,
    openModalEdit: false,
    generacionMovMasivo: [],
    loadingFile: false,
    excelFile: null,
    excelData: [],
    fileName: null,
    archivoEnProceso: false,
    resultsExportExcel: [],
    showSeeFormatModal: false,
    formatoTitle: "Formato de archivo para baja masiva",
    headersFormato: [],
    datosFormatoArchivo: [],
    dataToExportFormato: {},
    tiposMotivosEjemplo: [],
    estadosBenefEjemplo: "",
    excelDataTranslated: [],
  }),
  created() {
    this.setTipoProceso();
    this.setFecha();
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      createNuevoProcesoMovimientoMasivo:
        "afiliaciones/createNuevoProcesoMovimientoMasivo",
      generarProcesoMovimientoMasivo:
        "afiliaciones/generarProcesoMovimientoMasivo",
      fetchConfigMovMasivosByParams:
        "afiliaciones/fetchConfigMovMasivosByParams",
      postExcelNuevoProcesoMovimientoMasivo:
        "afiliaciones/postExcelNuevoProcesoMovimientoMasivo",
      getFormatoArchivoMovMasivoBaja:
        "afiliaciones/getFormatoArchivoMovMasivoBaja",
      getEstadosBenef: "afiliaciones/getEstadosBenef",
      getTiposMovMotivosBaja: "configAfiliaciones/getTiposMovMotivosBaja",
      setAlert: "user/setAlert"
    }),
    setFecha() {
      let fecha = new Date();
      fecha.setMonth(fecha.getMonth() + 1);
      fecha.setDate(1);
      this.fechaDesde = fecha.toISOString().substring(0, 10);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.setTipoProceso();
    },
    openModal(tipoProcesoSelected) {
      this.openVerConfigTipoProceso = tipoProcesoSelected;
      this.openModalEdit = true;
    },
    closeModalEdit() {
      this.openModalEdit = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.itemsNuevoMovMasivoPadrones = [];
    },
    async setTipoProceso() {
      if (this.fechaDesdeSelected == null) {
        this.tipoProcesos = [];
        this.tipoProcesoSelected = null;
      } else {
        const filters = {
          fechaDesde: this.parseDateToIso(this.fechaDesdeSelected)
        };
        const response = await this.fetchConfigMovMasivosByParams(filters);
        this.tipoProcesos = response;
      }
    },

    async applyFilters(excelDataTranslated) {
      this.setAlert({
        type: "warning",
        message: "Espere un momento, la carga de datos puede demorar."
      });
      try {
        // si selecciona el tipo de proceso desde archivo, carga los afiliados de acuerdo al excel
        if (this.tipoProcesoSelected.desdeArchivo) {
          this.isLoading = true;
          this.showFilters = false;
          this.customizeFiltersApplied();
          const response = await this.postExcelNuevoProcesoMovimientoMasivo({
            archivoBajaMasiva: excelDataTranslated,
            incluirGrupoFamiliar: this.tipoProcesoSelected.incluirGrpFamiliar
          });

          this.generacionMovMasivo = [];
          this.itemsNuevoMovMasivoPadrones = [];
          this.resultsExportExcel = [];
          if (response.status === 200) {
            this.isLoading = false;
            this.generacionMovMasivo = response.data.data.resultadoAfiliados;
            this.itemsNuevoMovMasivoPadrones = response.data.data.resumenAgrupa;
            if (response.data.data.errores.length != 0) {
              this.resultsExportExcel = response.data.data.errores;
              this.modalExportarExcel = true;
            } else {
              this.setAlert({
                type: "success",
                message: "Importado con éxito."
              });
            }
          }
          this.isLoading = false;
          //carga filtros normalmente
        } else {
          this.isLoading = true;
          this.showFilters = false;
          this.customizeFiltersApplied();
          const filters = {
            bcProcId: parseInt(this.tipoProcesoSelected.bcpTipoId),
            fecha: this.parseDateToIso(this.fechaDesdeSelected),
            periodo: this.periodo
          };
          const response = await this.createNuevoProcesoMovimientoMasivo(
            filters
          );
          this.itemsNuevoMovMasivoPadrones = response.resultado;
          this.generacionMovMasivo = response.generacion;
          this.isLoading = false;
        }
      } catch {
        this.isLoading = false;
        this.setAlert({
          type: "error",
          message:
            "No hay datos disponibles que coincidan con el filtro aplicado"
        });
      }
    },
    resetProcesoMasivo() {
      this.itemsNuevoMovMasivoPadrones = [];
      this.generacionMovMasivo = [];
      this.excelFile = null;
      this.excelDataTranslated = [];
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fecha",
          label: "Fecha de consulta desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.tipoProcesoSelected.bcpTipoNombre) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoProcesoSelected.bcpTipoNombre",
          label: "Tipo de proceso",
          model: this.tipoProcesoSelected.bcpTipoNombre
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(2, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.excelFile) {
        this.filtersApplied.splice(3, 1, {
          key: "fileName",
          label: "Archivo Excel",
          model: this.fileName
        });
      }
    },
    openModalProcesarMovMasivo() {
      this.dialog = true;
      this.isLoading = true;
      this.itemsMovMasivoGenerado = this.generacionMovMasivo;
      this.isLoading = false;
    },
    async guardarNuevoMovMasivo() {
      this.loadingProceso = true;
      const data = {
        procesoDto: this.generacionMovMasivo,
        bcProcId: this.tipoProcesoSelected.bcpTipoId,
        fecha: this.parseDateToIso(this.fechaDesdeSelected),
        periodo: this.periodo,
        fechaMov: this.parseDateToIso(this.fechaDesdeSelected)
      };
      const res = await this.generarProcesoMovimientoMasivo(data);
      if (res.status === 200) {
        this.setAlert({
          type: "success",
          message: "Actualización realizada con éxito"
        });
        this.itemsMovMasivoGenerado = [];
        this.$router.push({ name: "ConsultaMovMasivoPadrones" });
      }
      this.loadingProceso = false;
    },
    cleanChar(str, char) {
      while (true) {
        var result_1 = str.replace(char, "");
        if (result_1 === str) {
          break;
        }
        str = result_1;
      }
      return str;
    },
    exportarNuevosMovMasivos() {
      let result = [];
      this.itemsMovMasivoGenerado?.forEach(x =>
        result.push({
          ["Número de afiliado"]: this.cleanChar(x.numero),
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.nroDoc,
          ["Apellido/s y nombre/s"]: x.benNombre,
          ["Convenio"]: x.convenio,
          ["Plan"]: x.planNombre,
          ["Fórmula usada"]: x.formulaUsada
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Nuevo movimiento masivo");
    },
    exportActualizacionGenerada() {
      let result = [];
      this.generacionMovMasivo?.forEach(x =>
        result.push({
          ["Número de afiliado"]: this.cleanChar(x.numero),
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.nroDoc,
          ["Apellido/s y nombre/s"]: x.benNombre,
          ["Convenio"]: x.convenio,
          ["Plan"]: x.planNombre,
          ["Fórmula usada"]: x.formulaUsada
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Generación nuevo mov. masivo");
    },
    readExcel() {
      if (this.excelFile) {
        try {
          this.loadingFile = true;
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileName = this.excelFile.name;
            this.excelDataTranslated = [];
            this.excelData[0]?.filas.forEach(x => {
              this.excelDataTranslated.push({
                cuil: x["Cuil"],
                tipoDocumento: x["Tipo documento"],
                documento: x["Numero documento"],
                numeroAfiliado: x["Numero afiliado"],
                fechaBaja: this.parseDateToIso (helpersExcel.translateDate(x["Fecha de baja"])),
                nuevoEstado: x["Nuevo estado de baja"],
                motivo: x["Motivo"],
                observaciones: x["Observaciones"]
              });
            });
            this.loadingFile = false;
            this.applyFilters(this.excelDataTranslated);
          } else {
            setTimeout(this.readExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loadingFile = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    getArchivo(rowsToIgnore) {
      //accede por primera vez si es false
      if (this.archivoEnProceso == false) {
        this.loadingFile = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(
            this.excelFile,
            rowsToIgnore
          );
        }
      }
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["Cuil"]: x.cuil,
          ["Tipo documento"]: x.tipoDocumento,
          ["Numero documento"]: x.documento,
          ["Numero afiliado"]: x.numeroAfiliado,
          ["Fecha de baja"]: x.fechaBaja,
          ["Nuevo estado de baja"]: x.nuevoEstado,
          ["Motivo"]: x.motivo,
          ["Error"]: x.error
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle errores importación");
      this.setAlert({ type: "success", message: "Importado con éxito." });
      this.toggleModalExportarExcel();
    },
    async openModalSeeFormatoArchivo() {
      const formatoArchivo = await this.getFormatoArchivoMovMasivoBaja();
      this.datosFormatoArchivo = formatoArchivo.data.data;
      const motivos = await this.getTiposMovMotivosBaja();
      motivos.forEach(
        x => (this.tiposMotivosEjemplo += "-" + x.value.toString() + "\n")
      );
      const estadosBenef = await this.getEstadosBenef();
      estadosBenef.forEach(
        x => (this.estadosBenefEjemplo += "-" + x.value.toString() + "\n")
      );
      this.headersFormato = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Orden campo",
          align: "start",
          sortable: false,
          value: "ordenCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        }
      ];
      this.dataToExportFormato = {
        formatoName: "Baja masiva formato",
        data: [
          {
            ["Cuil"]: "20999999994 ",
            ["Tipo documento"]: "DNI",
            ["Numero documento"]: "99999999",
            ["Numero afiliado"]: "111222333444555",
            ["Fecha de baja"]: "18-12-2022",
            ["Nuevo estado de baja"]:`Ejemplos: ${this.estadosBenefEjemplo.toString()}` ,
            ["Motivo"]: `Ejemplos: ${this.tiposMotivosEjemplo.toString()}`,
            ["Observaciones"]: "Observaciones (opcional)",
          }
        ]
      };
      this.showSeeFormatModal = true;
    },
    toggleModalExportarExcel() {
      this.modalExportarExcel = !this.modalExportarExcel;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
